.card-wrapper {
  display: grid;
  align-items: center;
  grid-auto-flow: column;
  grid-gap: 0.5rem;
  grid-template-columns: auto max-content;
  grid-template-areas: 'card count';
  position: relative;
}
.list-consequences .card-wrapper {
  grid-template-columns: max-content auto;
  grid-template-areas: 'count card';
}

.card {
  --card-bg: var(--color-dark);
  background-color: var(--card-bg);
  border: 2px solid var(--cause-active, var(--color-dark));
  border-radius: 1.7rem;
  /* height: 3rem; */
  padding: 0 0.8em;
  padding: 0.8em;
  display: flex;
  align-items: center;
  display: grid;
  /* I believe this can be max-content, instead of a hard coded number, now that we are using icons */
  grid-template-columns: max-content 1fr max-content max-content;
  grid-gap: 0 1rem;
  position: relative;
  grid-area: card;
  transition: border-color 200ms ease, background-color 300ms ease;
}

.card-button {
  opacity: 0.75;
}

.card-button:hover {
  opacity: 1;
}

.card-button-disabled-true .card {
  --card-bg: var(--color-dark-shade);
  color: var(--color-white);
}

.card:hover {
  /* box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.25); */
  --card-bg: var(--color-tooltip-bg);
  --card-bg: transparent;
  --card-bg: var(--color-dark-rgba);
  /* border-color: var(--color-tooltip-bg); */
}

.card-hovered:not(.card-selected),
.card:not(.card-selected):hover {
  border-color: var(--card-bg);
  /* border-color: var(--color-tooltip-bg); */
  /* border-color: var(--color-charcoal); */
}


.card.card-selected {
  --cause-active: var(--color-root-causes);
}

.card.card-expanded {
  min-height: 3rem;
  height: max-content;
}

.card.is-active {
  border: 2px solid var(--color-end-cons);
}


.is-active.card-hovered:not(.card-selected),
.is-active.card:not(.card-selected):hover {
  border: 2px solid var(--color-end-cons);  
  /* border-color: var(--color-tooltip-bg); */
  /* border-color: var(--color-charcoal); */
}

.card.card-expanded .text-ellipsis {
  white-space: normal;
  overflow: hidden;
  text-overflow: unset;
}

.card-hovered {
  --card-bg: var(--color-dark-rgba);
}

.card.card-faded {
  opacity: 0.5;
}

.card-count {
  --rotate: 45deg;
  margin-left: auto;

  width: 1.5rem;
  height: 1.5rem;
  border-radius: 50%;
  display: grid;
  align-content: center;
  justify-content: center;
  background-color: #2d2f3c;
  transform: rotate(var(--rotate));

  opacity: 0.5;
  grid-area: count;
}

.card-count__inner {
  transform: rotate(calc(var(--rotate) * -1));
}

.list-causes .card-count {
  border-top-right-radius: 0;
}
.list-consequences .card-count {
  border-top-left-radius: 0;
}

.list-consequences .card-count {
  --rotate: -45deg;
}

.card-anchor {
  position: absolute;
  top: 0;
  height: 100%;
  width: 1px;
}

.card-anchor--left {
  left: 0;
}

.card-anchor--right {
  right: 0;
}

.card-body {
  overflow: hidden;
  padding-right: 0.25rem;
}

.card-overflow {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.card-button-wrapper {
  height: auto;
  font-size: 11px;
}

.card-button-container {
}

.card-button-wrapper .text-bold.text-ellipsis {
  margin-right: 10px;
}

.card-button.card {
  height: 1.8rem;
  grid-template-columns: 1fr;
  padding: 0 1.15rem;
}

.card-bottom-hr {
  background-color: var(--color-gray);
  border-color: var(--color-gray);
  color: var(--color-gray);
  width: calc(100% - 2.12rem); /*based off of hypotenuse of 1.5rem numbers next to cards*/
  opacity: 0.25;
}

.consequences .card-bottom-hr {
  margin-left: 2.12rem; /*based off of hypotenuse of 1.5rem numbers next to cards*/
}

.causes .card-bottom-hr {
  margin-right: 2.12rem;
}

.list-consequences.buttons-list {
  margin-left: 2.12rem;
}

.card-button-icon {
  width: 1rem;
  bottom: 6px;
  position: absolute;
}

.card-close__cta {
  position: relative;
  width: 20px;
  height: 20px;
  background-color: var(--color-dark-shade);
  border-radius: 50%;
}

.card-close__icon {
  position: absolute;
  top: 50%;
  left: 50%;
  max-width: 14px;
  transform: translate(-50%, -50%);
}

.card-close__icon .cls-1 {
  fill: var(--color-white);
}

.card-trends {
  grid-column: 1 / -1;
  grid-row: 2;
  height: 100px;
}

.card-body .text-small {
  max-width: 80%;
}
