:root {
  --app-bottom-height: 34vh;
  --observation-wrapper-height: 34vh;
  --observation-header-height: 60px;
  --observation-body-height: calc(
    var(--observation-wrapper-height) - var(--observation-header-height)
  );

  --app-top-columns-minmax: minmax(250px, 400px);
  --app-top-columns-empty-minmax: minmax(50px, 1fr);
  --app-top-height: calc(100vh - var(--app-bottom-height));
  --app-grid-columns: 1fr 1fr 1fr;
  --top-bar: 60px;

  --font-size-12: 0.76rem;
  --font-size-14: 0.875rem;
  --font-size-16: 1rem;
  --font-size-18: 1.1rem;

  --spacing-10: 0.65rem;
  --spacing-20: 1.25rem;
  --spacing-30: 1.45rem;
  --spacing-40: 2.2rem;
}

.App {
  min-height: 100vh;
  width: 100vw;
  scroll-behavior: smooth;
}

.App__top {
  display: grid;
  grid-template-columns: var(--app-grid-columns);
  grid-template-rows: var(--top-bar) 1fr;
  height: calc(var(--app-top-height) - var(--top-bar));
  position: relative;

  grid-template-areas:
    'causes verify transit counteractions consequences'
    'causes verify transit counteractions consequences';
  grid-template-columns:
    var(--app-top-columns-minmax) var(--app-top-columns-empty-minmax) 400px var(
      --app-top-columns-empty-minmax
    )
    var(--app-top-columns-minmax);

  padding: 0 2rem;
  grid-column-gap: 1rem;
}

/* .header-background {
  background: linear-gradient(
    180deg,
    rgba(64, 66, 78, 1) 0%,
    rgba(64, 66, 78, 1) 40%,
    rgba(64, 66, 78, 0.5) 73%,
    rgba(64, 66, 78, 0.4) 86%,
    rgba(64, 66, 78, 0.3) 93%,
    rgba(64, 66, 78, 0) 100%
  );
  grid-column: 1 / -1;
  grid-row: 1 / 2;
  z-index: 3;
  pointer-events: none;
} */

/* .consequences,
.causes,
.transit {
  display: grid;
  grid-template-rows: 3.5rem;
  grid-auto-rows: 1fr;
  grid-auto-flow: row;
  grid-template-columns: 400px;
  justify-content: center;
  height: calc(var(--app-top-height));
} */

.consequences,
.causes,
.transit {
  display: contents;
}

.causes {
  grid-area: causes;
  top: calc(-1 * var(--top-bar) + 10px);
}
.consequences {
  grid-area: consequences;
  top: calc(-1 * var(--top-bar) + 10px);
}

.list-container {
  overflow-x: hidden;
  overflow-y: scroll;
  height: var(--app-top-height);
  transform: translateY(calc(var(--top-bar) * -1));
  padding-top: calc(var(--top-bar) + 1rem);
  z-index: 2;
  overflow-anchor: none;
}

.list-main {
  width: 100%;
  display: grid;
  grid-template-columns: 100%;
  grid-auto-rows: max-content;
  grid-gap: 0.5em;
  padding: 1rem 0 3rem; /* This adds a bit of space on top and bottom when using overflow scroll of the parent elements */
}

.is-list-bottom-true {
  padding-bottom: 1.2rem;
}

.buttons-list {
  display: grid;
  grid-auto-columns: max-content;
  grid-gap: 10px;
  grid-auto-flow: column;
  justify-content: center;
}

.card-button-disabled-true {
  pointer-events: none;
}

.number-indicator {
  background-color: var(--override-color, var(--color-observations));
  width: 1.2rem;
  height: 1.2rem;
  font-size: 1rem;
  border-radius: 50%;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  color: black;
  font-weight: bold;
}

.margin-0 {
  margin: 0;
}

.opacity-25 {
  opacity: 0.25;
}
.opacity-50 {
  opacity: 0.5;
}

.opacity-70 {
  opacity: 0.3;
}

.flex {
  display: flex;
}

.flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.flex-align-center {
  align-items: center;
}

.flex-justify-start {
  justify-content: flex-start;
}

.flex-justify-end {
  justify-content: flex-end;
}

.text-center {
  text-align: center;
}
.text-right {
  text-align: right;
}

.text-regular,
.text-normal {
  font-weight: normal;
}

.text-bold {
  font-weight: bold;
}

.text-small {
  font-size: 0.8rem;
}

.text-uppercase {
  text-transform: uppercase;
}
.text-capitalize {
  text-transform: capitalize;
}

.border-bottom {
  border-bottom: 1px #000 solid;
}
.width-100 {
  width: 100%;
}

.grid-center {
  display: grid;
  grid-auto-flow: column;
  grid-template-columns: max-content;
  grid-gap: 1rem;
  align-items: center;
}

.align-self-end {
  align-self: end;
}

.state-label {
  background-color: #40424e;
  color: var(--color-observations);
  padding: 0.25em 0.5em;
  border-radius: 1em;
  text-transform: uppercase;
  font-weight: bold;
  width: max-content;
  height: max-content;
  align-self: center;
  font-size: 0.8em;
}

.card-state {
  align-self: center;
  height: 100%;
  display: grid;
  color: var(--card-icon-color, var(--color-observations));
}
.card-state-grey {
  align-self: center;
  height: 100%;
  display: grid;
  color: #a3a5b1;
}

.card-state__icon {
  align-self: center;
  width: 2em;
  height: 2em;
  font-size: 14px;
}

.card__sensor-icon {
  align-self: center;
  width: 1.5em;
  height: 2.5em;
  font-size: 14px;
}

.transit {
  grid-area: transit;
  grid-template-rows: max-content 1fr;
  grid-template-rows: 3.5rem;
  grid-auto-rows: 1fr;
  grid-template-areas:
    'head'
    'body';
}

.transit-head {
  justify-self: center;
  grid-area: head;
  z-index: 5;
  position: relative;
}
.transit-head:empty:after {
  content: 'EMPTY';
  opacity: 0;
  font-size: 1.3rem;
}

/* Deprecated?  */
.transit-anchor {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 1px;
  display: flex;
  align-content: center;
}

.transit-anchor--consequences {
  right: 0;
  left: auto;
}

.transit-line {
  grid-row: 3;
  color: var(--line-color, transparent);
  height: 100%;
  align-self: center;
  opacity: 0;
  justify-self: center;
  display: flex;
  stroke: 2px;
  stroke-dasharray: 4px;
  stroke-dashoffset: 4px;
}

.transit-line--active {
  --line-color: black;
  opacity: 1;
}

.transit-svg {
  height: calc(100% - 1rem);
  margin-top: auto;
}

.transit-svg__line {
  stroke-width: 2px;
  stroke: currentColor;
}

.verify-cause {
  display: flex;
  flex-direction: column;
  height: 66vh;
  opacity: 1;
  justify-content: center;
  z-index: 3;
}

.verify-cause-box {
  width: 350px;
  background-color: #383945;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

.verify-cause-box-header {
  width: 100%;
  background-color: #2d303b;
  border-radius: 10px;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  padding: 10px 20px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.verify-cause-icon-container > svg {
  height: 20px;
  width: 20px;
  margin-right: 20px;
  cursor: default;
}

.verify-cause-box-body {
  width: 100%;
  padding: 10px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

.verify-cause-box-body-quiz {
  width: 330px;
  border-radius: 10px;
  padding: 10px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  transition: background-color ease 0.3s;
}
.verify-cause-box-body-quiz:hover {
  background-color: #2d303b;
}

.verify-cause-box-body-quiz.active {
  background-color: #2d303b;
}

.verify-cause-box-body-question {
  width: 100%;
  font-size: 0.8rem;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  user-select: none;
  cursor: pointer;
}

.verify-cause-box-body-answer {
  width: 80%;
  margin-top: 15px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.verify-sensor-box-body-answer {
  width: 80%;
  padding-top: 2px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}

.commited-answer {
  background-color: var(--color-observations);
  color: #2d303b;
}

.verify-cause-button {
  border: 1px solid transparent;
  font-size: 0.85rem;
  font-weight: 500;
  border-radius: 20px;
  padding: 5px 15px;
  margin-left: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.verify-cause-button.active {
  border-color: transparent;
  background-color: var(--color-observations);
  color: #2d303b;
  margin: 2px 0;
}

.tooltip-causes {
  z-index: 999999999999;
}