.timeline-wrapper {
  --group-name-width: 110px;
  background-color: var(--color-dark-shade);
  flex-grow: 1;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 100%;
  grid-template-areas: 'timeline';
  /* padding: 0rem 0 3rem; */
}

.timeline-wrapper > * {
  grid-area: timeline;
}

.timeline-slots {
  grid-area: timeline;
  display: grid;
  grid-auto-rows: max-content;
  grid-gap: 0.5rem;
  grid-template-columns: var(--group-name-width) 1fr;
  padding: 1rem 2rem 4rem 0;
  /* grid-template-columns: max-content 1fr; */
  /* align-items: center; */
}

.timeline-slots--segments {
  grid-template-rows: 1fr;
  padding: 0rem 2rem 0rem 0;
}

.timeline-group {
  --group-name-width: 110px;
  display: grid;
  grid-template-columns: var(--group-name-width) 1fr;
  grid-template-areas: 'group-name timelineGroup';
  row-gap: 0.2rem;
}

.timeline-group:nth-child(n + 2) .timeline-observation:nth-child(2) {
  border-top: 1px solid var(--color-dark);
}

.timeline-group[data-stale-disabled='true'] {
  --stale-disabled-color: var(--color-gray);
  --stale-disabled-color-opacity: var(--color-charcoal);
  --stale-disabled-color-light: rgb(238, 238, 241);
}

.timeline {
  --padding: 0.6rem;
  grid-column: 2;
  display: grid;
  grid-column-gap: 0.5rem;
  padding: var(--padding) 1.1rem;
  grid-template-columns: 5rem 15rem 1fr 7rem;
  /* grid-template-rows: var(--card-height, 100%) max-content; */
  grid-template-rows: max-content max-content;
  grid-template-areas:
    'time info timeline state'
    '.    .    chart    .';
  align-items: center;
  position: relative;
  background-color: var(--stale-disabled-color-opacity, var(--bg, transparent));
  border-radius: 0.2rem;
  scroll-margin: 40px;
  /* padding-bottom: 0px; */
  /* cursor: pointer; */
}

.timeline[data-passed='true'] {
  display: none;
  cursor: pointer;
}

.timeline[data-expanded='true'] {
  --bg: var(--color-dark);
  box-shadow: 1px 1px var(--color-green), -1px -1px var(--color-green), -1px 1px var(--color-green),
    1px -1px var(--color-green);
  box-shadow: 1px 1px rgb(80 227 194 / 0.2), -1px -1px rgb(80 227 194 / 0.2),
    -1px 1px rgb(80 227 194 / 0.2), 1px -1px rgb(80 227 194 / 0.2);
}

.timeline:hover {
  /* --bg: var(--color-dark); */
}

.timeline-observation__counter {
  background-color: var(--stale-disabled-color, var(--color-dark));
  height: max-content;
  align-self: center;
  margin-top: 0.75em;
  padding: 0.2em 1em 0.2em;
  padding-left: 1.4rem;
  justify-content: center;
  border-radius: 0 2rem 2rem 0;
  display: grid;
  grid-template-columns: max-content 1fr;
  gap: 0.5rem;
  text-transform: uppercase;
  font-size: 0.8rem;
  align-items: center;
  justify-items: left;
  z-index: 1;
  position: sticky;
  top: 1rem;
  align-self: start;
}

.timeline-observation__counter span:first-of-type {
  font-size: 1rem;
  font-weight: bold;
}

.timeline-observations__divider {
  border: 0;
  height: 0;
  border-bottom: 2px solid var(--color-dark);
  z-index: 20;
  width: 100%;
}

/* .timeline-observation {
  border-radius: 0.23rem;
  outline: 2px solid rgba(0, 0, 0, 0);
} */

.timeline-observation::before {
  content: '';
  grid-column: 1 / -1;
  grid-row: 1 / -1;
  z-index: 0;
  margin: calc(var(--padding) * -1);
  width: calc(100% + (var(--padding) * 2));
  height: calc(100% + (var(--padding) * 2));
  border-radius: 0.5rem;
}

.timeline-observation[data-selected='true'] {
  box-shadow: -1px -1px var(--color-active), 1px 1px var(--color-active),
    -1px -1px var(--color-active), 1px -1px var(--color-active), -1px 1px var(--shadow);
}

.timeline-observation[data-selected='true'] .text-ellipsis {
  white-space: normal;
  overflow: hidden;
  text-overflow: unset;
}

.timeline-observation[data-active='true'] {
  --bg: var(--color-dark);
  --color-orange-hsl: hsl(38, 91%, 60%);
  --accent-h: 38;
  --accent-s: 91%;
  --accent-l: 60%;

  background-color: var(--bg, var(--color-dark));
}

.timeline-observation[data-disabled='true'] {
  pointer-events: none;
}

.timeline-segments {
  grid-template-rows: 1fr;
  pointer-events: none;
  padding-top: 0;
  padding-bottom: 0.5em;
}

.timeline__sensor-label {
  font-size: 0.8rem;
  color: var(--color-gray);
}

.timeline__track {
  --graph-height: 100px;
  --bar-height: 1.25rem;
  --bar-height-half: calc(var(--bar-height) * 0.5);
  --bar-padding: 0.5rem;
}

.timeline__track {
  height: 100%;
  width: 100%;
  position: relative;
  grid-column: timeline;
  grid-row: timeline / chart;
  --track-color-h: var(--accent-h, 0);
  --track-color-s: var(--accent-s, 0%);
  --track-color-l: var(--accent-l, 95%);
  --calculated-a: calc(0.9 - var(--fading-stage) / 1.3);
  color: hsl(var(--track-color-h), var(--track-color-s), var(--track-color-l));
  overflow: hidden;
}

.timeline__track:before {
  content: '';
  display: block;
  height: calc(100% + (var(--padding) * 2));
  top: calc(var(--padding) * -1);
  bottom: calc(var(--padding) * -1);
  position: absolute;
  left: -1px;
  width: 15%;
  background: linear-gradient(
    90deg,
    var(--stale-disabled-color-opacity, var(--bg, var(--color-dark-shade))),
    rgba(0, 0, 0, 0) 100%
  );
  z-index: 10;
  pointer-events: none;
}

.timeline__track-graph {
  width: 100%;
}

.timeline__chart-wrapper {
  grid-area: chart;
  /* overflow: hidden; */ /* "hides" when collapsing */
}

.timeline-chart {
  /* padding: 1rem 0; */
}

.timeline__bar {
  color: hsla(
    var(--track-color-h),
    var(--track-color-s),
    var(--track-color-l),
    var(--calculated-a)
  );
  position: absolute;
  height: 1.2rem;
  left: calc(var(--start) * 1%);
  width: calc(var(--width) * 1%);
  min-width: 2px;
  border-radius: 0.6rem;
  background-color: currentColor;
  transition: border-radius 0.01s ease-in-out;
}

.timeline-observation[data-expanded='true'] .timeline__bar {
  border-bottom-left-radius: 0px;
}

.timeline__bar:after {
  content: '';
  display: block;
  width: 100%;
  background: #f2f2f2;
  opacity: 0.1;
  height: 100%;

  /*CHANGE ME*/
  position: relative;
  top: 0;
  padding-bottom: calc(100px + 1.2rem);

  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;

  transition: opacity 300ms ease;
}

.timeline-observation[data-expanded='false'] .timeline__bar:after {
  height: 0px;
  padding-bottom: 0px;
  opacity: 0;
}

.timeline__bar:hover {
  color: hsla(
    var(--track-color-h),
    var(--track-color-s),
    calc(var(--track-color-l) + var(--calculated-a) * 0.8%),
    calc(var(--calculated-a * 0.85))
  );
}

[data-ongoing='true'].timeline__bar {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  right: 0;
  width: auto;
}

.timeline__svg {
  width: 100%;
  height: 100%;
  min-height: 100%;
}

.timeline__hours {
  grid-area: timeline;
  justify-self: flex-end;
  z-index: 10;
  /* width: calc(100% - var(--group-name-width, 100px)); */
  width: 100%;
  height: 100%;
  position: relative;
  /* padding-bottom: 2rem; */

  /* Chrome drops a lot of frames because of resizing because of the scaling of an SVG. This solves it */
  /* position: absolute; */
}

.timeline__hour {
  --line-opacity: 0.3;
  position: absolute;
  left: calc(var(--position) * 1%);
  bottom: 0;
  height: 100%;
  width: 1px;
  width: var(--line-width, 1px);
}

.timeline__now {
  --line-opacity: 1;
  --line-width: 1px;
  left: calc(100% + 0px);
}

.timeline__now:before,
.timeline__now:after {
  --triangle-size: 5px;
  content: '';
  width: 0;
  height: 0;
  border-left: var(--triangle-size) solid transparent;
  border-right: var(--triangle-size) solid transparent;
  color: currentColor;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}

.timeline__now:before {
  bottom: 100%;
  border-top: var(--triangle-size) solid;
}
.timeline__now:after {
  top: 100%;
  border-bottom: var(--triangle-size) solid;
}

.timeline__hour-line {
  position: absolute;
  bottom: 0;
  height: 100%;
  width: 100%;
  background-color: currentColor;
  opacity: var(--line-opacity);
}

.timeline__label {
  position: fixed;
  bottom: 0%;
  transform: translateX(-50%);
  padding-top: 0.25rem;
  padding-bottom: 1rem;
  font-size: 0.8rem;
  z-index: 20;
  color: #ccc;
  text-shadow: 0px 2px 2px rgba(0, 0, 0, 0.52);
}

.timeline__hour:first-child .timeline__label {
  opacity: calc((100 - var(--position)) / 10);
}

.timeline-head {
  grid-area: info;
  display: grid;
  z-index: 10;
  /* max-height: var(--card-height); */
  grid-template-rows: var(--card-height) max-content;
  grid-template-columns: 1fr;
  grid-template-areas:
    'label id'
    'description .'
    'verification .';
  align-items: center;
  height: 100%;
  overflow: hidden;
  transition: max-height 300ms ease;
}

.timeline__state-label-container {
  z-index: 10;
  width: 1.2rem;
  height: 1.2rem;
  background-color: transparent;
  position: absolute;
  right: 0;
  transform: none;
}

[data-ongoing='true'] .timeline__state-label-container {
  --border-radius-label: 0 1rem 1rem 0;
  border-radius: var(--border-radius-label);
  background-color: var(--stale-disabled-color, currentColor);
  position: absolute;
  transform: translateX(100%);
}

.timeline__state-label-container[data-state='ended'] {
  display: none;
}

.timeline__state-label-container[data-state='ended'] * {
  display: none;
}

.timeline__state-current-label {
  z-index: 10;
  display: block;
  height: 100%;
  border-radius: var(--border-radius-label, 1rem);
  border-radius: 1rem;
  background-color: var(--stale-disabled-color-light, var(--color-observations));

  color: var(--color-dark);
  /* align-items: center; */
  /* text-transform: uppercase; */
  transform: translateX(0);
  transition: transform 300ms ease;
}
[data-ongoing='true']:last-child .timeline__state-current-label {
  transform: translateX(25%);
}

.timeline-head__label {
  grid-area: label;
  cursor: pointer;
}

.timeline-head__description {
  grid-area: description;
}

.timeline-head__verification {
  grid-area: verification;
}

.timeline__current-state-label {
  grid-area: state;
  z-index: 10;
}

.timeline__time {
  grid-area: time;
  align-self: start;
  font-size: 0.8rem;
  font-weight: bold;
  z-index: 10;
  color: var(--color-observations);
  /* color: hsl(38, 91%, 60%);
  --calculated-h: 38;
  --calculated-s: 91%;
  --calculated-l: calc(60% + var(--fading-stage) * 39%);
  --calculated-a: calc(1 - var(--fading-stage) / 2.5);
  color: var(
    --stale-disabled-color-light,
    hsla(var(--calculated-h), var(--calculated-s), var(--calculated-l), var(--calculated-a))
  ); */
}

.track__icon {
  width: inherit;
  height: inherit;
}

.timeline-value {
  grid-column: state;
  grid-row: 1 / 1;
  font-size: 0.85rem;
  color: white;
  align-self: start;
}
