.state-circle {
  display: flex;
  z-index: -5;
  pointer-events: none;
  justify-self: center;
  width: 100%;
  height: 100%;
  position: relative;
}

.state-circle-no-z {
  display: flex;
  pointer-events: none;
  justify-self: center;
  width: 100%;
  height: 100%;
  position: relative;
}

.circle__logo {
  height: 100%;
  height: auto;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  pointer-events: none;
}

.circle__logo-svg {
  width: 50%;
  margin: auto;
}

.circle__pulsar {
  height: auto;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.circle__text {
  text-align: center;
  width: 100%;
}

.circle__text {
  display: flex;
  align-self: center;
  justify-content: center;
  z-index: 1;
  position: relative;

  color: #f2f2f2;
  opacity: 0.6;
  font-style: italic;
}

.circle__shade {
  transform-origin: center;
}

.circle__shade {
  stroke: currentColor;
  fill: currentColor;
  stroke-opacity: 0.4;
  stroke-width: 2px;
  transform-origin: center;
}
