@font-face {
  font-family: 'Roboto Condensed';
  font-style: normal;
  font-weight: 400;
  src: local('Roboto Condensed'), local('RobotoCondensed'),
    url('fonts/RobotoCondensed-Regular.woff2') format('woff2');
}

@font-face {
  font-family: 'Roboto Condensed';
  font-style: normal;
  font-weight: 600;
  src: local('Roboto Condensed'), local('RobotoCondensed'),
    url('fonts/RobotoCondensed-Bold.woff2') format('woff2');
}

@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  src: local('Roboto'), url('fonts/Roboto-Bold.woff2') format('woff2');
}

:root {
  --font-size-min: 15;
  --font-size-max: 17;
  --viewport-min: 400;
  --viewport-max: 1500;

  --color-orange: #f6b03a;
  --color-red: #ee6352;

  --color-yellow: #e7ff4b;

  --color-charcoal: #40424e;
  --color-charcoal-light: #4c4e5a;
  --color-gray: #9a989f;
  --color-gray-light: #c2c8cc;
  --color-white: #fff;
  --color-dark: #2d2f3c;
  --color-dark-shade: #373946;
  --color-darkest: #191e28;
  --color-tooltip-bg: var(--color-dark-shade);
  /* --color-dark: rgba(21, 23, 35, 0.7); */
  --color-dark-rgba: rgb(32, 33, 47);
  --color-dark-opacity: rgba(21, 23, 35, 0.3);
  --color-green: rgba(80, 227, 194, 1);
  --color-green-light: rgba(80, 227, 194, 0.5);
  --color-active: var(--color-green-light);

  --bg-easing: ease-in;

  /* Color updates */
  --color-observations: #ffdd7b; /* yellow for observations, sensors, verify causes, ranking */
  --color-root-causes: #69ce8f; /* green for root and selected causes */
  --color-end-cons: #1a9cdb; /* blue for consequences */
  --color-actuators: #ff7c4d; /* orange for actuators, preventions */
}

html {
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  font-family: 'Roboto Condensed', sans-serif;
  font-size: calc(
    (var(--font-size-min) * 1px) + (var(--font-size-max) - var(--font-size-min)) *
      ((100vw - (var(--viewport-min) * 1px)) / (var(--viewport-max) - var(--viewport-min)))
  );
  background-color: var(--color-charcoal);
  overflow-x: hidden;
  overflow-y: hidden;
  min-height: 100vh;
  height: 100vh;
}
::-webkit-scrollbar-thumb {
  background: #39394c;
  border-radius: 10px;
  box-shadow: none;
}

::-webkit-scrollbar-track {
  background: inherit;
}
::-webkit-scrollbar {
  width: 0px;
  height: 10px;
}

body {
  margin: 0;
  padding: 0;
  color: #fff;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

button {
  font: inherit;
  cursor: pointer;
  background: transparent;
  border: none;
  outline-style: none;
  color: currentColor;
  text-align: inherit;
  padding: 0;
  pointer-events: all;
}

.button-wrapper {
  display: block;
  width: 100%;
}

.observations-button-wrapper {
  display: block;
  width: 320px;
}

svg {
  display: inline-block;
  backface-visibility: hidden;
  overflow: visible;
}

.color-gray {
  color: var(--color-gray);
}

.text-ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.section-title {
  text-transform: uppercase;
  font-size: 1.1rem;
}

.helpKeyTitle {
  margin-bottom: 0px;
  margin-top: 0px;
}

.colorGuide {
  display:flex;
  flex-direction: row;
  width: 100%;
  justify-content: left;
  border-radius: 5px;
  /* background-color: var(--color-dark-shade); */
  padding: 10px;
}

.colorGuide.legend-box {
  padding-top: 35px;
  padding-bottom: 35px;
  margin-top: 0px;
}

.colorItem {
  /* display: inline; */
  flex-direction: row;
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;

}

.legend-box.actuator-box {
  background-color: rgba(227, 123, 81, 0.3);
  border: 1px solid var(--color-actuators);

}

.legend-box.consequences-box {
  background-color: rgba(91, 155, 216, 0.3);
  border: 1px solid var(--color-end-cons);

}

.legend-box.causes-box {
  background-color: rgba(143, 205, 145, 0.3);
  border: 1px solid var(--color-root-causes);

}

.legend-box.observations-box {
  background-color: rgba(246, 221, 128, 0.3);
  border: 1px solid var(--color-observations);

}


.iconArea:not(.actuator-box):not(.ui-box) {
  min-height: 350px;
}

.observations-color {
  background-color: var(--color-observations);
  padding: 3px;
}

.consequences-color {
  background-color: var(--color-end-cons);
  padding: 3px;

}

.actuator-color {
  background-color: var(--color-actuators);
  padding: 3px;

}

.causes-color {
  background-color: var(--color-root-causes);
  /* background: var(--color) */
  padding: 3px;

}

.colorRow {
  display: flex;
  flex-direction: column;
  gap:1em;
  width: 100%;
  padding-left: 10px;
  padding-right: 10px;
  margin-left: 0px;
}

.guide-circle {
  border-radius: 5px;
  color: black;
  text-align: center;
  font-weight: bold;
  line-height: 2;
  width: 100%;
}

.iconColContainer {
  flex-direction: row;
  display: flex;
  gap: 10px;
}

.iconArea {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 40px;
  padding-bottom: 50px;
  /* min-height: 420px; */
}

.iconArea.legend-box {
  padding-top: 10px;
  height: 100%;
}

.rankRow {
  padding: 20px;
  padding-bottom: 10px;
  padding-top: 10px;
}

.iconLabel {
  font-size: 0.7rem;
}

.iconHeader {
  padding-bottom: 0px;
  padding-top: 0px;
  margin-bottom: 0px;
}

.blurbArea a {
  white-space: nowrap;
}

.insights {
  width: 75%;
  /* margin-bottom: auto; */
  display: flex;
  flex-direction: column;
}

.bottomRow ul {
  margin-top: 0;
  margin-bottom: 0;
}

.bottomRow ul, a {
  list-style-type: none;
  margin-left: 0;
  padding: 0;
  color: var(--color-white);
  /* text-decoration: none; */
}

.bottomRow .links {
  width: 25%;
  /* margin: auto; */
  display: flex;
  flex-direction: column;
}

.iconRow {
  flex-direction: row;
  justify-content: space-between;
  display: flex;
  width: 100%;
  gap: 10px;
}

.bottomRow {
  display: flex;
  flex-direction: row;
  padding: 20px;
  padding-top: 0px;
  padding-bottom: 0px;
  gap: 10px;
}

.bottomRow span {
  font-size: 0.7rem;
}

.legend-box {
  background-color: var(--color-charcoal-light);
  border-radius: 5px;
  margin: 10px;
  gap: 0.5em;
  padding: 10px;
  margin-left: 0px;
  padding-top: 20px;
  margin-bottom: auto;
  height: 100%;
  margin-right: 0px;
}

.legend-box.last {
  margin-right: 0px;
}

.listHeading {
  display: flex;
  flex-direction: column;
}

.helpKeyCol1 {
  display: flex;
  flex-direction: column;
  padding-right: 20px;
  padding-left: 20px;
}

.iconCol {
  width: 25%;
  padding-top: 0px;
  padding-bottom: 0px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.iconCol.last {
  padding-right: 20px;
}

.colorCol {
  padding: 20px;
  padding-top: 0px;
  padding-left: 0px;
  padding-bottom: 0px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

}

.iconItem {
  justify-content: space-between;
  display: flex;
  flex: 1;
  align-items: center;
  flex-direction: column;
  border-radius: 5px;
  padding-top: 10px;
  padding-bottom: 10px;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
  background-color: rgba(0,10,0, 0.08);
}


.iconItem.blank {
  background-color: transparent;
  box-shadow: none;
}

.iconLabel {
  font-weight: bold;
  margin-top: 10px;
}

.observations-box .iconItem svg {
  color: var(--color-darkest);
  background-color: var(--color-observations);
  border-radius: 50%;
  padding: 7px;
}

.causes-box .iconItem svg {
  color: var(--color-darkest);
  background-color: var(--color-root-causes);
  border-radius: 50%;
  padding: 7px;
}

.actuator-box .iconItem svg {
  color: var(--color-darkest);
  background-color: var(--color-actuators);
  border-radius: 50%;
  padding: 7px;
}
.consequences-box .iconItem svg {
  color: var(--color-darkest);
  background-color: var(--color-end-cons);
  border-radius: 50%;
  padding: 7px;
}


.ui-box .iconItem svg {
  color: var(--color-darkest);
  background-color: var(--color-gray-light);
  border-radius: 50%;
  padding: 7px;
}


.iconArea h3 {
  margin-top: 0;
}

.iconArea h2 {
  margin-top: 0;
}

.cardContainer h5 {
  margin-top: 0px;
  margin-bottom: 0px;
}

.rankHelpCards {
  display: flex;
  flex-direction: row;
  margin-left: auto;
  width: 75%;
  margin-top: auto;
  margin-bottom: auto;
}

.cardContainer span {
  font-size: 0.7rem;
  margin-top: 8px;
  margin-bottom: 10px;
}

.helpScreenKey {

  @media and (max-width: 995px), 
  screen and (max-height: 700px) {


  }
}

.helpScreenKey h1, h2, h3, h5, span {
  font-size: 0.9rem;
}


.rankingTooltipContainer {
  flex-direction: column;
  display: flex;
  gap: 5px;
  width: 18%;
  margin-top: auto;
  margin-bottom: auto;
}

.rankingAreaCard {
  flex: 1 1 0px;
}
.cardContainer {
  width: 33%;
  display: flex;
  flex-direction: column;
  margin-left: 10px;
}
.cardContainer button{
  margin-top: auto;
}
.rankItem {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.rankingBox {
  padding-left: 10px;
  display: flex;
  flex-direction: row;
  font-size: 1.9em;
  /* margin-top: auto; */
}

.rankingDonut {
  display: flex;
  align-items: center;
  width: 7%;
}

.blurbArea {
  padding: 20px;
}

.iconCol h5 {
  margin-top: 5px;
  margin-bottom: 5px;
}