[data-operational='true'] {
  --override-color: var(--color-green);
}

[data-operational='false'] {
  --override-color: var(--color-observations);
}

.equipment-container {
  margin-left: 50%;
  align-items: center;
  height: 40px;
  display: grid;
  grid-auto-flow: column;
  grid-gap: 0.5rem;
  grid-auto-columns: max-content;
  padding-left: 15px;
  padding-right: 1rem;
  cursor: pointer;
  border-radius: 30px 0 0 30px;
  background-color: #2d2f3c;

  pointer-events: all;
  /* fix this! we want relative units! */
  margin-top: 10px;
  margin-bottom: 10px;
  /* fix this! we want relative units! */

  transition-property: margin-left;
  /* transition-timing-function: ease-in-out, ease-in-out; */
  transition-duration: 0.3s;
  /* transition: background-color 0.3s ease; */
}

.equipment-status-good-icon {
  border-radius: 50%;
  height: 80px;
  width: 80px;
  justify-self: center;
  align-self: center;
  margin: auto;
  background-color: #4a7672;
  position: relative;
  top: 30%;
}

.equipment-status-good-icon-inner {
  border-radius: 50%;
  height: 94%;
  width: 94%;
  margin: auto;
  background-color: #50e3c2;
  position: relative;
  top: 3%;
}

.equipment-status-good-label {
  margin: auto;
  text-align: center;
  position: relative;
  top: 35%;
}

.equipment-lists:after {
  /* pointer-events: none;
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #2d2f3c;
  z-index: -1; */
  /* transition: opacity 300ms ease-in-out, transform 400ms cubic-bezier(0.68, 0.04, 0.19, 1.03) 00ms; */
}

.equipment-lists[data-toggle='false']:after {
  opacity: 0;
  transform: translateX(15%);
  /* transition: opacity 300ms ease-in-out, transform 500ms cubic-bezier(0.68, 0.04, 0.19, 1.03) 0ms; */
}

.equipment-lists[data-toggle='true']:after {
  opacity: 1;
  pointer-events: all;
}

.equipment-lists[data-toggle='false'] {
  pointer-events: none;
}

.equipment-lists[data-toggle='false'] .equipment-list {
  /* padding: 0; */
}

.equipment-lists[data-toggle='false'] .equipment-container .close-icon {
  /* background-color: transparent; */
  /* transition: background-color 0.3s ease; */
  visibility: hidden;
  right: -50px;
}

.equipment-lists[data-toggle='true'] {
  background-color: #2d2f3c;
  /* transition: background-color 0.3s ease; */
}

.equipment-lists[data-toggle='true'] .equipment-container {
  background-color: #2d2f3c;
  margin-left: 0;
}

.equipment-lists,
.notification-list {
  z-index: 1001;
  width: 20rem;
  position: fixed;
  left: 100%;
  height: var(--app-top-height);
  transform: translateX(-100%);
  cursor: pointer;
}

.equipment-lists {
  display: grid;
  grid-template-rows: max-content 1fr;
  overflow: scroll;
  overflow-x: hidden;
  height: calc(var(--app-top-height) - var(--top-bar));
  top: calc(var(--top-bar) - 2px);
  transition: background-color 0.4s ease;
}

.equipment-lists::-webkit-scrollbar {
  display: none;
  /* overflow-y: scroll; */
  pointer-events: none;
}

.equipment-list {
  list-style: none;
  display: grid;
  grid-auto-flow: row;
  grid-auto-rows: max-content;
  grid-gap: 1rem;
  overflow-y: scroll;
  padding: 1.2rem;
  /* height: calc(var(--app-top-height) - (2 * var(--top-bar))); */
  /* top: var(--top-bar); */
  border-top: 1px solid var(--color-dark-shade);
  pointer-events: all;
}

.equipment-list::-webkit-scrollbar {
  display: none;
  /* overflow-y: scroll; */
}

.equipment-list__item {
  padding: 0;
}

.animate-list__item {
  position: relative;
  pointer-events: none;
  z-index: 2;
}

.equipment-card {
  display: grid;
  grid-auto-flow: row;
  grid-gap: 0.25rem;
}

.equipment-icon-placeholder,
.status-indicator {
  display: inline-block;
  width: 1rem;
  height: 1rem;
  border: 2px solid;
  border-radius: 50%;
  color: var(--color-observations);
  position: relative;
}

.equipment-icon-placeholder:before {
  content: '';
  display: block;
  background-color: var(--color-observations);
  position: absolute;
  height: 70%;
  width: 1px;
  bottom: 40%;
  left: 50%;
  transform: translateX(-50%);
  border: 4px solid #2d2f3c;
  box-sizing: content-box;
}

.status-indicator {
  width: 3px;
  height: 3px;
  margin-right: 5px;
}

.notifications {
  /*  display: contents; */
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  height: calc(var(--app-top-height) - var(--top-bar));
}

.notifications-bar {
  grid-area: bar;
  position: sticky;
  top: 0;
}

.notification {
  --background-color: #2d2f3c;
  background-color: var(--background-color);
  font-size: 14px;
  padding: 1rem 1rem 1rem 1.8rem;
  border-radius: 0.25rem;

  display: grid;
  grid-auto-flow: column;
  /* grid-template-columns: max-content 100px 150px max-content; */
  grid-gap: 0.8rem;
  border-left: 5px solid var(--color-observations, var(--color-red));
  position: relative;
  justify-items: space-between;
  align-items: center;
  z-index: 1000;

  /* opacity: 0;
  pointer-events: none;
  visibility: hidden;
  display: none; */
}

.check-icon {
  width: 40%;
  position: relative;
  top: 30%;
  left: 30%;
}

.close-icon {
  height: auto;
  width: 25px;
  position: absolute;
  top: 18px;
  right: 10px;
  visibility: visible;
  transition: right 0.3s ease-in-out 0s;
}

.notification-pointer {
  position: absolute;
  bottom: 100%;
  right: 40%;
}

.notification-icon {
  width: 0.8rem;
  height: 0.8rem;
  border: 3px solid var(--color-observations);
  border-radius: 50%;
}

.notification-list {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding-right: 5px;
  pointer-events: none;
  margin-top: calc(var(--top-bar) - 2px);
  height: calc(var(--app-top-height) - var(--top-bar));
}

.notification-list__item {
  /* position: absolute; */
  z-index: 1000;
  pointer-events: all;
  top: var(--top-bar);
}
