.observations {
  background-color: var(--color-dark-shade);
}

.observations-scroll {
  min-height: var(--observation-body-height);
  max-height: var(--observation-body-height);

  overflow-y: scroll;
  -ms-overflow-style: none;

  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr;
  grid-template-areas: 'timeline-wrapper';
}

.observations-scroll > * {
  grid-area: timeline-wrapper;
}

.observations-scroll::-webkit-scrollbar {
  display: none;
}

.observations__wrapper {
  background-color: var(--color-dark-shade);
}

.observations__timeline {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: var(--observation-header-height) var(--observation-body-height);
  grid-template-areas: 'observationshead' 'timeline';
  position: relative;
}

.observations-head {
  grid-area: observationshead;
  display: grid;
  grid-template-columns: max-content 1fr;
  gap: 2rem;
  align-items: center;
  z-index: 300;
  border-bottom: 1px solid var(--color-charcoal);
  background-color: var(--color-dark-shade);
  padding-left: 1rem;
  height: var(--top-bar);
}

.observations__suppressing-stale-container {
  display: flex;
  font-size: 0.8rem;
  display: grid;
  grid-gap: 0.5rem;
  grid-auto-flow: column;
  cursor: pointer;
}

.observation-card {
  display: grid;
  grid-column-gap: 1.5rem;
  padding: 0.5em 1rem;
  grid-template-columns: 400px 1fr 100px;
  grid-template-rows: var(--card-height, 100%) max-content;
  grid-template-areas:
    'info timeline time'
    'info timeline .';
  align-items: center;
  position: relative;
}

.observation-card {
  /* --card-height: 3rem; */
  border-left: 4px solid transparent;
}

.observation-card.is-selected {
  --bg: var(--color-dark);
  --border: var(--color-green-light);
  --accent: var(--color-observations);
  background-color: var(--bg);
  border-color: var(--border);
}

.observation-info {
  grid-area: info;
  display: grid;
  /* max-height: var(--card-height); */
  grid-template-rows: var(--card-height) max-content;
  grid-template-columns: 4rem 1fr auto;
  grid-template-areas:
    'state label id'
    '. description .';
  align-items: center;
  overflow: hidden;
  transition: max-height 300ms ease;
}

.observation-state {
  justify-self: left;
}

.observation .observation-state {
  grid-area: state;
}
.observation-id {
  grid-area: id;
}
.observation-label {
  grid-area: label;
}
.observation-description {
  grid-area: description;
}

.observation-timeline {
  grid-area: timeline;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  position: relative;
}

.observation-time {
  grid-area: time;
}

.observation-info-main {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.observations__equipment-status-container {
  display: flex;
  justify-content: flex-end;
  padding: 0.5rem;
}

.observations__equipment-status-container > button {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem 1rem;
  text-transform: uppercase;
}

.observations__equipment-status-container > button > span {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--color-observations);
  color: var(--color-dark);
  width: 1.5rem;
  height: 1.5rem;
  border-radius: 1.5rem;
}
