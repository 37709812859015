.trend {
  position: relative;
  color: #979797;
}

.trend__svg {
  width: 100%;
  height: 65px;
}

.trend__path {
  stroke-width: 1.5px;
}

.trend__tooltip {
  position: absolute;
  bottom: 100%;

  pointer-events: none;
  will-change: transform;
  backface-visibility: hidden;
  z-index: 100;
}

[data-hidden='true'] {
  opacity: 0;
  display: none;
}

.trend__tooltip-content {
  min-width: 5rem;
  background-color: #191e28;
  display: grid;
  color: white;
  grid-auto-flow: row;
  border-radius: 3px;
  transform: translateX(-50%);
  margin-top: -0.2em;
  font-size: 14px;
  padding: 2px 0;
}

.trend__tooltip-separator {
  width: 100%;
  margin: 0;
  background-color: #373946;
  height: 1px;
  opacity: 0.2;
}

.trend__tooltip-time,
.trend__tooltip-unit {
  display: grid;
  grid-auto-flow: row;
  grid-template-columns: 1.5em 1fr;
  grid-gap: 0.25rem;
  align-items: center;
  justify-content: space-between;
  padding: 0 5px;
}

.trend__limits-text.trend__no-data {
  font-style: normal;
}

.trend__tooltip-unit {
  color: var(--color-observations);
}
#trend__tooltip-value-icon {
  color: var(--color-observations);
  padding: 20%;
}
#trend__tooltip-value-icon-positive {
  color: var(--color-observations);
  padding: 20%;
}
#trend__tooltip-value-icon-negative {
  color: var(--color-gray);
  padding: 20%;
}

.trend__tooltip-icon {
  width: 1.25rem;
  height: 1.25rem;
  display: grid;
  align-content: center;
  justify-content: center;
  fill: currentColor;
}

.trend__tooltip-icon-placeholder:after {
  content: '';
  display: block;
  width: 1.1em;
  height: 1.1em;
  border-radius: 50%;
  border: 1px solid;
  box-shadow: 0px 0px 1px white;
}

.trend__limits {
  fill: currentColor;
  font-size: 14px;
}

.trend__limits-line {
  shape-rendering: auto;
}

.trend__limits-text {
  font-style: italic;
}
