.cardState {
  align-self: center;
  height: 100%;
  display: grid;
  color: var(--card-icon-color, var(--color-end-cons));
}

.cardStateIcon {
  align-self: center;
  width: 2em;
  height: 2em;
  font-size: 14px;
}

.card-wrapper {
  display: grid;
  align-items: center;
  grid-auto-flow: column;
  grid-gap: 0.5rem;
  grid-template-columns: auto max-content;
  grid-template-areas: 'card count';
  position: relative;
}
.list-consequences .card-wrapper {
  grid-template-columns: max-content auto;
  grid-template-areas: 'count card';
}

.buttonWrapper {
  display: block;
  width: 100%;
}
.card {
  --card-bg: #373946;
  background-color: #373946;
  /* border: 2px solid var(--cause-active, var(--color-dark)); */
  /* border-radius: 1.7rem; */
  /* height: 3rem; */
  /* padding: 0.3 0.8em; */
  padding: 1rem;
  display: flex;
  justify-content: space-between;
  width: 100%;
  transition: border-color 200ms ease, background-color 300ms ease;
  border-radius: 32px;
  border: 2px solid transparent;
}

.card-button-disabled-true .card {
  --card-bg: var(--color-dark-shade);
  color: var(--color-white);
}

.card:hover {
  /* box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.25); */
  /* --card-bg: var(--color-tooltip-bg);
  --card-bg: transparent;
  --card-bg: var(--color-dark-rgba); */
  background: #20212f;
  border-radius: 32px;
  border: 2px solid transparent;
  /* border-color: var(--color-tooltip-bg); */
}

.cardHovered:not(.cardSelected),
.card:not(.cardSelected):hover {
  border-color: var(--card-bg);
  border-radius: 32px;

  border: 2px solid transparent;
  /* border-color: var(--color-tooltip-bg); */
  /* border-color: var(--color-charcoal); */
}

.card.cardSelected {
  background-color: #2d2f3c;
  border: 2px solid #1a9cdb;
  border-radius: 32px;
}

.card.cardExpanded {
  min-height: 3rem;
  height: max-content;
}

.card.cardExpanded .text-ellipsis {
  white-space: normal;
  overflow: hidden;
  text-overflow: unset;
}

.cardHovered {
  --card-bg: var(--color-dark-rgba);
}

.cardBody {
  overflow: hidden;
  padding-right: 0.25rem;
}

.consequences .card-bottom-hr {
  margin-left: 2.12rem; /*based off of hypotenuse of 1.5rem numbers next to cards*/
}

.list-consequences.buttons-list {
  margin-left: 2.12rem;
}

.fixedWidth {
  width: 250px;
}
