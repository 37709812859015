.toast-list {
  position: absolute;
  width: 100%;
  margin: auto;
  left: 0;
  right: 0;
  top: 0;
}

.toast-list__item {
  position: relative;
  margin: auto;
  z-index: 1000;
  pointer-events: all;
  margin-bottom: 1rem;
  border-top: 3px solid var(--color-observations);
}

.toast-list__simple-notification {
  position: relative;
  margin: auto;
  z-index: 1000;
  pointer-events: all;
  margin-bottom: 1rem;
  border-top: 3px solid var(--color-green);
}

.rewind-icon {
  color: var(--color-dark);
  height: 2rem;
}
