.abnormals {
  display: grid;
  grid-template-areas:
    'timerange timerange timerange'
    ' . main . ';
  grid-template-columns: 0.6fr 2fr 0.6fr;
  grid-template-rows: max-content 1fr;
  gap: 2rem;
  padding: 2rem 0;
  width: 60%;
  min-width: 800px;
  margin: 0 auto;
  max-height: 100vh;
  overflow: scroll;
}

/* TimeRange */

.abnormals-timerange {
  grid-area: timerange;
  display: grid;
  grid-template-rows: max-content max-content 1fr;
}

.abnormals-timerange__main {
  background-color: var(--color-dark-shade);
  display: grid;
  grid-template-columns: 1fr 1fr;
  padding: 1rem;
  border-radius: 0.5rem;
}

.abnormals-timerange__date-inputs {
  justify-self: center;
  justify-items: center;
  display: grid;
  grid-template-columns: max-content 1fr;
  align-items: center;
  gap: 1rem;
  padding: 1rem;
  background-color: var(--color-dark);
  border-radius: 0.5rem;
}

.abnormals-timerange__date-input-container {
  display: grid;
  grid-template-columns: max-content 1fr;
}

.abnormals-timerange__date-input {
  height: 2rem;
  display: grid;
  grid-template-columns: 3fr 1.8fr;
}

input {
  height: 100%;
  font-size: 1.1rem;
  font-family: 'Roboto Condensed', sans-serif;
}

::-webkit-datetime-edit {
  padding: 0.5rem;
}

.input__without-buttons {
  min-width: max-content;
}

.input__without-buttons::-webkit-clear-button,
.input__without-buttons::-webkit-inner-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  -o-appearance: none;
  -ms-appearance: none;
  appearance: none;
}

.from-time {
  width: 200px;
}
/*  */

.abnormals-list {
  grid-area: main;
  display: grid;
  gap: 0.5rem;
}

.abnormals-list__main {
  display: grid;
  gap: 1em;
}

.abnormals-card {
  display: grid;
  grid-template-rows: max-content 1fr max-content;
  background-color: var(--color-dark);
  padding: 0.5em;
  border-radius: 0.5em;
  gap: 1em;
}

.abnormals-card__head {
  display: grid;
  grid-template-columns: 1fr 1fr max-content;
  font-size: 1rem;
  margin-top: 0.3em;
}

.abnormals-card__name-container {
  display: flex;
  flex-direction: column;
}

.abnormals-card__name-container span:nth-of-type(1) {
  text-transform: uppercase;
  font-size: 1.2rem;
  font-weight: bold;
}
.abnormals-card__name-container span:nth-of-type(2) {
  text-transform: uppercase;
  font-size: 0.9rem;
  font-weight: normal;
  color: var(--color-gray);
}

.abonrmals-card__current-state {
  text-transform: unset;
  text-align: right;
}

.abnormals-card__value-highlight {
  font-weight: bold;
}

.abnormals-card__limits {
  display: grid;
  gap: 0.2em;
  grid-template-rows: 1fr 1fr;
  grid-template-areas: 'high' 'low';
}

.abnormals-card__limit-low {
  grid-area: low;
  display: flex;
  justify-content: space-between;
}

.abnormals-card__limit-high {
  grid-area: high;
  display: flex;
  justify-content: space-between;
}

.abnormals-card__row {
  display: contents;
  margin-top: 0.2em;
}

.abnormals-card__table {
  display: grid;
  grid-template-columns: max-content 1fr 1fr max-content;
  gap: 1em;
  margin-top: 1em;
  background-color: var(--color-dark-shade);
  padding: 1em;
  border-radius: 0.5rem;
  align-items: center;
}

.abnormals-card__table-head {
  display: contents;
}

.abnormals-card__table-cell {
  display: grid;
  grid-auto-columns: auto;
  grid-auto-flow: column;
  gap: 1em;
}

.abnormals-card__table div:nth-of-type(n + 5) {
  display: none;
}

.abnormals-card__table-expanded div:nth-of-type(n + 5) {
  display: contents;
}

.abnormals-card__table-counter {
  margin-top: 0.7em;
  text-align: right;
  font-size: 1.2em;
}
.abnormals-card__table-counter span:nth-of-type(2) {
  color: var(--color-gray);
}
.abnormals-card__table-counter span:nth-of-type(1) {
  margin: 0 0.3em;
}

.abnormals-card__expand-btn {
  float: right;
  font-size: 1.3rem;
}

.abnormals-card__expand-btn:hover {
  font-weight: bold;
}
