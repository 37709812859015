.tooltip-wrapper {
  position: relative;
  /* font-size: 14px; */
  --bg: var(--color-tooltip-bg);
  z-index: 10;

  display: grid;
  grid-auto-rows: max-content;
  grid-auto-flow: column;
  grid-gap: 0.5rem;
  align-content: center;
}

.help-tooltip {
  position: relative;
  /* font-size: 14px; */
  --bg: var(--color-tooltip-bg);
  background-color: rgba(55,57,70, 0.7);
  z-index: 10;
  display: grid;
  grid-auto-rows: max-content;
  grid-auto-flow: column;
  grid-gap: 0.5rem;
  /* z-index: 999999; */
}

.help-tooltip-box {
  background-color: rgba(55,57,70, 0.7);

}

.help-tooltip :not(button)  {
  /* z-index: 1000; */
  /* background-color: blue; */
}

.to-center {
  justify-content: center;
}
.tooltip-container {
  position: relative;
}

.tooltip-wrapper[data-toggled='visible'] .tooltip-message {
  opacity: 1;
  pointer-events: all;
}

.tooltip-wrapper[data-width='max-content'] .tooltip-message {
  width: max-content;
  --bg: var(--color-dark-shade);
  box-shadow: 0px -2px 3px 2px var(--color-dark);
}

.tooltip-wrapper[data-toggled='visible'] .tooltip-message-bottom {
  opacity: 1;
  pointer-events: all;
}

.tooltip-wrapper[data-width='max-content'] .tooltip-message-bottom {
  width: max-content;
  --bg: var(--color-dark-shade);
  box-shadow: 0px -2px 3px 2px var(--color-dark);
}

.tooltip-message-bottom:before {
  --pointer-size: 0.5rem;
  z-index: -1;
  position: absolute;
  top: -10%;
  left: 40%;
  transform: rotate(180deg);
  content: '';
  width: 0;
  height: 0;
  border-top: solid var(--pointer-size) var(--bg);
  border-left: solid var(--pointer-size) transparent;
  border-right: solid var(--pointer-size) transparent;
}

.tooltip-message:before {
  --pointer-size: 0.5rem;
  z-index: -1;
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
  content: '';
  width: 0;
  height: 0;
  border-top: solid var(--pointer-size) var(--bg);
  border-left: solid var(--pointer-size) transparent;
  border-right: solid var(--pointer-size) transparent;
}

.tooltip-message-bottom {
  opacity: 0;
  position: absolute;
  left: 40%;
  bottom: -390%;
  width: 200px;
  transform: translateX(-50%);
  text-align: center;
  padding: 0.6rem;
  margin: 1rem 0;
  background-color: var(--bg);
  border-radius: 5px;
  color: rgba(255, 255, 255, 0.7);
  transition: opacity 100ms ease-in 170ms;
  pointer-events: none;
  z-index: 10;
}

.tooltip-message {
  opacity: 0;
  position: absolute;
  left: 50%;
  bottom: 100%;
  width: 200px;
  transform: translateX(-50%);
  text-align: center;
  padding: 0.6rem;
  margin: 1rem 0;
  background-color: var(--bg);
  border-radius: 5px;
  color: rgba(255, 255, 255, 0.7);
  transition: opacity 100ms ease-in 170ms;
  pointer-events: none;
  z-index: 10;
}
